/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useRouter } from 'next/router';
import { LoginRoute, RegisterRoute } from 'server/routes';
import { signUpEvents } from '@lib/events/signUp.event';

import ButtonSecondary from '@components/controls/Button/ButtonSecondary';

import { AuthHeaderButtonProps } from '@type/auth/authButtons';

import style from './AuthHeaderButton.module.scss';

const AuthHeaderButton = ({
  title,
  authButtonTitle,
  isBackButton = false,
  authType,
}: AuthHeaderButtonProps) => {
  const router = useRouter();
  const authRoute = authType === 'login' ? LoginRoute : RegisterRoute;
  const authEvents = () => {
    if (authType === 'login') {
      signUpEvents.clickLogin();
    } else {
      signUpEvents.clickRegister();
    }
  };

  return (
    <div className={style.authHeaderButtonsHolder}>
      {isBackButton ? (
        <ButtonSecondary onClick={() => router.back()}>Nazad</ButtonSecondary>
      ) : (
        <>
          <p>{title}</p>
          <ButtonSecondary
            href={authRoute.generateUrl()}
            doLog={() => authEvents()}
          >
            {authButtonTitle}
          </ButtonSecondary>
        </>
      )}
    </div>
  );
};

export default AuthHeaderButton;
