import dynamic from 'next/dynamic';
import Layout from '@layouts/responsive/Layout/Layout';

const MobileOneColSimpleLayout = dynamic(
  () =>
    import(
      '@layouts/mobile/MobileOneColumnSimpleLayout/MobileOneColumnSimpleLayout'
    )
);

export const mobileOneColSimpleLayout = (page) => (
  <Layout>
    <MobileOneColSimpleLayout cookies={page.props.cookies}>
      {page}
    </MobileOneColSimpleLayout>
  </Layout>
);
