import dynamic from 'next/dynamic';
import Layout from '@layouts/responsive/Layout/Layout';

const OneColumnSimpleLayout = dynamic(
  () => import('@layouts/desktop/OneColumnSimpleLayout/OneColumnSimpleLayout')
);

export const oneColSimpleLayout = (page) => (
  <Layout>
    <OneColumnSimpleLayout cookies={page.props.cookies}>
      {page}
    </OneColumnSimpleLayout>
  </Layout>
);
